import {
  faArrowDown,
  faArrowUp,
  faCalculator,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TripleToggleSwitch from "components/TripleToggleSwitch/TripleToggleSwitch";
import { contractAddress } from "config";
import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  Cell,
  Column,
  Row,
  TableInstance,
  TableState,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import "./DataTable.scss";

export function isDataItem(obj: any): obj is DataItem {
  return (
    obj &&
    typeof obj === "object" &&
    "owner" in obj &&
    "address" in obj.owner &&
    typeof obj.owner.address === "string" &&
    "powerScore" in obj &&
    typeof obj.powerScore === "number" &&
    "rarityScore" in obj &&
    typeof obj.rarityScore === "number" &&
    "rankingScore" in obj &&
    typeof obj.rankingScore === "number"
  );
}

export function isDataItemWithAddress<D extends Record<string, unknown>>(
  obj: any,
): obj is D & { owner: { address: string } } {
  return (
    obj &&
    typeof obj === "object" &&
    "owner" in obj &&
    typeof obj.owner === "object" &&
    "address" in obj.owner &&
    typeof obj.owner.address === "string"
  );
}

export function isItemWithSaleInfo<D extends Record<string, unknown>>(
  obj: any,
): obj is D & {
  saleInfo: {
    owner: { address: string };
    price: string;
    token: string;
    marketUrl: string;
    usdAmmount: string;
  };
} {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const result =
    "saleInfo" in obj &&
    typeof obj.saleInfo === "object" &&
    "owner" in obj.saleInfo &&
    typeof obj.saleInfo.owner === "object" &&
    "address" in obj.saleInfo.owner &&
    typeof obj.saleInfo.owner.address === "string" &&
    "price" in obj.saleInfo &&
    typeof obj.saleInfo.price === "number" &&
    "token" in obj.saleInfo &&
    typeof obj.saleInfo.token === "string" &&
    "marketUrl" in obj.saleInfo &&
    typeof obj.saleInfo.marketUrl === "string" &&
    "usdAmmount" in obj.saleInfo &&
    typeof obj.saleInfo.usdAmmount === "string";
  return result;
}

export type CustomColumn<T extends Record<string, unknown>> = Column<T> & {
  mobileWidth?: number;
  skipForMobilePortraitMode?: boolean;
};

interface DataTableProps<D extends Record<string, unknown>> {
  columns: CustomColumn<D>[];
  data: D[];
  lastDate: string;
  address: string;
}

interface CustomTableInstance<D extends Record<string, unknown>>
  extends TableInstance<D> {
  setFilter: (columnId: string, updater: string | undefined) => void;
  // Pagination
  state: TableState<D> & {
    pageIndex: number;
    pageSize: number;
    sortBy: [{ id: string; desc: boolean }];
  };
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  gotoPage: (updater: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  setSortBy: (updater: [{ id: string; desc: boolean }]) => void;
}

export interface DataItem {
  name: string;
  powerScore: number;
  rarityScore: number;
  rankingIndex: number;
  [key: string]: unknown;
}

const DataTable = <D extends Record<string, unknown>>({
  columns,
  data,
  lastDate,
  address,
}: DataTableProps<D>) => {
  const [showOnlyUserEntries, setShowOnlyUserEntries] = React.useState(false);
  const [showOnlyUnlockedEntries, setShowOnlyUnlockedEntries] =
    React.useState(true);
  const [showOnlyLockedEntries, setShowOnlyOnlyLockedEntries] =
    React.useState(true);
  const [showLockedUnlockedAllEntries, setShowLockedUnlockedAllEntries] =
    React.useState(1);
  const [showOnlyEntriesForSale, setShowOnlyEntriesForSale] =
    React.useState(false);
  const [sorting, setSorting] = React.useState<{ id: string; desc: boolean }>({
    id: "rankingIndex",
    desc: false,
  });

  const useDeviceOrientation = () => {
    const [isLandscape, setIsLandscape] = useState(false);

    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setIsLandscape(width > height);
    };

    useEffect(() => {
      handleResize(); // Initialize the isLandscape state on first render
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return { isLandscape };
  };

  const { isLandscape } = useDeviceOrientation();

  function renderSortingArrow(columnId: string) {
    if (sorting.id === columnId) {
      return sorting.desc ? (
        <FontAwesomeIcon icon={faArrowDown} className="icon-mobile-arrow" />
      ) : (
        <FontAwesomeIcon icon={faArrowUp} className="icon-mobile-arrow" />
      );
    }
    return "";
  }

  const getSortingValue = (
    item: D,
    sortingId: string,
    sortingDesc: boolean,
  ) => {
    if (sortingId === "usdAmmount") {
      const defaultValue = sortingDesc ? -Infinity : Infinity;
      return isItemWithSaleInfo(item)
        ? parseFloat(item.saleInfo.usdAmmount)
        : defaultValue;
    } else if (sortingId === "rarityScore") {
      return isDataItem(item) ? item.rarityScore : 0;
    } else if (sortingId === "powerScore") {
      return isDataItem(item) ? item.powerScore : 0;
    } else if (sortingId === "rankingIndex") {
      return isDataItem(item) ? item.rankingIndex : 0;
    } else {
      return isDataItem(item) ? parseInt(item.name.split("#")[1], 10) : 0;
    }
  };

  const filteredAndSortedData = useMemo(() => {
    const filtered = showOnlyUserEntries
      ? data.filter((item: D) => {
          if (isDataItemWithAddress(item)) {
            if (isItemWithSaleInfo(item)) {
              return item.saleInfo.owner.address === address;
            }
            return item.owner.address === address;
          }
          return false;
        })
      : data;

    const filteredOnlyLocked = showOnlyUnlockedEntries
      ? filtered.filter(
          (item: D) =>
            isDataItemWithAddress(item) &&
            item.owner.address !== contractAddress,
        )
      : filtered;

    let filteredUnlocked = filtered;

    if (showLockedUnlockedAllEntries == 0) {
      filteredUnlocked = filtered.filter(
        (item: D) =>
          isDataItemWithAddress(item) && item.owner.address !== contractAddress,
      );
    } else if (showLockedUnlockedAllEntries == 2) {
      filteredUnlocked = filtered.filter(
        (item: D) =>
          isDataItemWithAddress(item) && item.owner.address === contractAddress,
      );
    }

    const filteredOnlyForSale = showOnlyEntriesForSale
      ? filteredUnlocked.filter((item: D) => isItemWithSaleInfo(item))
      : filteredUnlocked;

    if (filteredOnlyForSale && filteredOnlyForSale.length > 0) {
      const sortedData = filteredOnlyForSale.slice().sort((a, b) => {
        const aValue = getSortingValue(a, sorting.id, sorting.desc);
        const bValue = getSortingValue(b, sorting.id, sorting.desc);
        return sorting.desc ? bValue - aValue : aValue - bValue;
      });

      return sortedData;
    } else {
      return [];
    }
  }, [
    data,
    showOnlyUserEntries,
    showOnlyEntriesForSale,
    showLockedUnlockedAllEntries,
    address,
    sorting,
  ]);
  const initialState = { hiddenColumns: ["combined"] };
  const instance = useTable<D>(
    {
      columns,
      data: filteredAndSortedData,
      initialState,
    },
    useFilters,
    useSortBy,
    usePagination,
  ) as unknown as CustomTableInstance<D>;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setSortBy,
  } = instance;

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    columnId: string,
  ) => {
    setFilter(columnId, e.target.value);
  };

  function handleHeaderClick(sortId: string) {
    console.log(sortId);
    if (sortId != "url") {
      // Assuming mobile view width is 768px or less
      const currentSorting = sorting.id + (sorting.desc ? "_desc" : "");

      if (currentSorting.startsWith(sortId)) {
        // If the current sorting is already based on this column, toggle the order
        updateSorting(sortId + (sorting.desc ? "" : "_desc"));
      } else {
        // Otherwise, set the new sorting column in ascending order
        updateSorting(sortId);
      }
    }
  }

  function updateSorting(sortValue: string) {
    const desc = sortValue.endsWith("_desc");
    const columnId = desc ? sortValue.slice(0, -5) : sortValue;
    setSorting({ id: columnId, desc: desc });
  }

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateSorting(e.target.value);
  };

  const labels = {
    left: {
      title: "",
      value: "left",
    },
    right: {
      title: "",
      value: "right",
    },
    center: {
      title: "",
      value: "center",
    },
  };

  const onChangeTripleToggle = (value: any) => {
    if (value == "left") {
      console.log("left clicked");
      setShowLockedUnlockedAllEntries(0);
    } else if (value == "center") {
      console.log("center clicked");
      setShowLockedUnlockedAllEntries(1);
    } else {
      console.log("right clicked");
      setShowLockedUnlockedAllEntries(2);
    }
  };

  return (
    <>
      {!isMobile && (
        <div className="App">
          <div className="container">
            <div className="controls">
              <div className="hover-container ">
                <span className="info-span icon-question">
                  <FontAwesomeIcon icon={faHourglass} />
                </span>
                <div className="hover-text">Last fetch data: {lastDate}</div>
              </div>
              <a
                className="info-span icon-question rank"
                href="https://blog.spacerobotsclub.com/sladder-formula"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCalculator} title="Go to Ranking formula Page"/>
              </a>
              <div className="container controls-container">
                <div
                  className="controls-row"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "40px",
                  }}
                >
                  <div className="toggle-container">
                    <label className="label-text toggle-label">For sale</label>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setShowOnlyEntriesForSale(e.target.checked)
                        }
                        className="filter-input"
                      />
                      <span className="slider"></span>
                    </label>
                  </div>

                  <div className="toggle-container">
                    <label className="label-text toggle-label">My Robots</label>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setShowOnlyUserEntries(e.target.checked)
                        }
                        className="filter-input"
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                  <div className="toggle-container">
                    <label className="label-text toggle-label">
                      Open \ All \ Locked
                    </label>
                    <TripleToggleSwitch
                      labels={labels}
                      onChange={onChangeTripleToggle}
                    />
                  </div>

                  {/* <div className="toggle-container">
                    <label className="label-text toggle-label">Unlocked</label>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={showOnlyUnlockedEntries}
                        onChange={(e) =>
                          setShowOnlyUnlockedEntries(e.target.checked)
                        }
                        className="filter-input"
                      />
                      <span className="slider"></span>
                    </label>
                  </div> */}
                </div>
                <label className="label-text">
                  <input
                    type="text"
                    onChange={(e) => handleFilterChange(e, "combined")}
                    className="filter-input"
                    placeholder="search by id/address/herotag"
                  />
                </label>
              </div>
              {/* {!isMobile && (
                // <div className="container" style={{ display: "flex", alignItems: "center" }}>
                <label
                  className="label-text"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Sort:
                  <select
                    value={
                      sorting.id
                        ? sorting.id + (sorting.desc ? "_desc" : "")
                        : "name"
                    }
                    onChange={(e) => handleSortChange(e)}
                    className="filter-input"
                  >
                    <option value="ranking">Ranking ASC</option>
                    <option value="ranking_desc">Ranking DESC</option>
                    <option value="name">Name ASC</option>
                    <option value="name_desc">Name DESC</option>
                    <option value="ta">Attack ASC</option>
                    <option value="ta_desc">Attack DESC</option>
                    <option value="td">Defense ASC</option>
                    <option value="td_desc">Defense DESC</option>
                    <option value="tatd">Attack + Defense ASC</option>
                    <option value="tatd_desc">Attack + Defense DESC</option>
                    <option value="usdAmmount">Price ASC</option>
                    <option value="usdAmmount_desc">Price DESC</option>
                  </select>
                </label>
                // </div>
              )} */}
            </div>

            <table {...getTableProps()} className="custom-table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className={`header ${
                          column.id === "index" ? "center-align" : ""
                        }`}
                        style={{ width: column.width }}
                        onClick={() => handleHeaderClick(column.id)}
                      >
                        {column.render("Header")}
                        {renderSortingArrow(column.id)}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows
                  .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
                  .map((row: Row<D>, rowIndex: number) => {
                    prepareRow(row);

                    // Calculate the display index for the first column
                    const displayIndex = pageIndex * pageSize + rowIndex + 1;

                    return (
                      <tr
                        {...row.getRowProps()}
                        style={
                          isItemWithSaleInfo(row.original) &&
                          row.original.saleInfo.owner.address === address &&
                          !showOnlyUserEntries
                            ? {
                                backgroundColor: "#015949",
                              }
                            : isDataItemWithAddress(row.original) &&
                              row.original.owner.address === address &&
                              !showOnlyUserEntries
                            ? { backgroundColor: "#015949" }
                            : {}
                        }
                      >
                        {row.cells.map((cell: Cell<D>, cellIndex: number) => {
                          // Add center-align class for the specified columns
                          const isCenteredColumn = [
                            "index",
                            "rarityScore",
                            "powerScore",
                            "rankingIndex",
                            "usdAmmount",
                          ].includes(cell.column.id);
                          const cellClassName = isCenteredColumn
                            ? "cell center-align"
                            : "cell";

                          // Render the display index for the index column
                          if (cell.column.id === "index") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={cellClassName}
                              >
                                {displayIndex}
                              </td>
                            );
                          }
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={cellClassName}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="pagination-button left-span"
              >
                <span>{"<<"}</span>
              </button>{" "}
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="pagination-button left-span"
              >
                <span>{"<"}</span>
              </button>{" "}
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="pagination-button right-span"
              >
                <span>{">"}</span>
              </button>{" "}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="pagination-button right-span"
              >
                <span>{">>"}</span>
              </button>{" "}
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageCount}
                </strong>{" "}
              </span>
              <span>|</span>
              <span>
                Go to page:{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                  style={{ width: "40px" }}
                />
              </span>{" "}
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="App">
          <div className="container" style={{ marginTop: "10px" }}>
            <div className="controls">
              <div className="fetch-data-mobile">
                Last fetch data: {lastDate}
              </div>
              <div className="controls-row">
                <div className="toggle-container">
                  <label className="label-text-mobile  toggle-label">
                    for sale
                  </label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        setShowOnlyEntriesForSale(e.target.checked)
                      }
                      className="filter-input"
                    />
                    <span className="slider"></span>
                  </label>
                </div>

                <div className="toggle-container">
                  <label className="label-text-mobile toggle-label">
                    my robots
                  </label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      onChange={(e) => setShowOnlyUserEntries(e.target.checked)}
                      className="filter-input"
                    />
                    <span className="slider"></span>
                  </label>
                </div>

                <div className="toggle-container">
                  <label className="label-text-mobile toggle-label">
                    open\all\locked
                  </label>
                  <TripleToggleSwitch
                    labels={labels}
                    onChange={onChangeTripleToggle}
                  />
                </div>
              </div>
            </div>
            <div className="controls ">
              <label className="label-text-mobile-filter">
                <input
                  type="text"
                  onChange={(e) => handleFilterChange(e, "combined")}
                  className="filter-input-mobile"
                  placeholder="search by id/address/herotag"
                />
              </label>
            </div>

            <table {...getTableProps()} className="custom-table-mobile">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      const customColumn = column as CustomColumn<any>;
                      // Check if the column should be skipped for mobile portrait mode
                      if (
                        !isLandscape &&
                        customColumn.skipForMobilePortraitMode
                      ) {
                        return null;
                      }
                      return (
                        <th
                          {...column.getHeaderProps()}
                          className={`header ${
                            column.id === "index" ? "center-align" : ""
                          }`}
                          style={{ width: customColumn.mobileWidth }}
                          onClick={() => handleHeaderClick(column.id)}
                        >
                          {column.render("Header")}
                          {renderSortingArrow(column.id)}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows
                  .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
                  .map((row: Row<D>, rowIndex: number) => {
                    prepareRow(row);

                    // Calculate the display index for the first column
                    const displayIndex = pageIndex * pageSize + rowIndex + 1;

                    return (
                      <tr
                        {...row.getRowProps()}
                        style={{
                          backgroundColor:
                            !showOnlyUserEntries &&
                            isDataItemWithAddress(row.original) &&
                            row.original.owner.address === address
                              ? "#ff9800"
                              : "",
                        }}
                      >
                        {row.cells.map((cell: Cell<D>, cellIndex: number) => {
                          const customColumn = cell.column as CustomColumn<any>;

                          // Check if the cell should be skipped for mobile portrait mode
                          if (
                            !isLandscape &&
                            customColumn.skipForMobilePortraitMode
                          ) {
                            return null;
                          }
                          // Add center-align class for the specified columns
                          const isCenteredColumn = [
                            "index",
                            "ta",
                            "td",
                            "tatd",
                            "rankingIndex",
                          ].includes(cell.column.id);
                          const cellClassName = isCenteredColumn
                            ? "cell center-align"
                            : "cell";

                          // Render the display index for the index column
                          if (cell.column.id === "index") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={cellClassName}
                              >
                                {displayIndex}
                              </td>
                            );
                          }
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={cellClassName}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="pagination pagination-container">
              <div className="pagination-button-container" style={{}}>
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="pagination-button left-span"
                >
                  <span>{"<<"}</span>
                </button>{" "}
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="pagination-button left-span"
                >
                  <span>{"<"}</span>
                </button>{" "}
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="pagination-button right-span"
                >
                  <span>{">"}</span>
                </button>{" "}
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="pagination-button right-span"
                >
                  <span>{">>"}</span>
                </button>{" "}
                {/* <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>{" "} */}
                {/* <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {">"}
                </button>{" "} */}
                {/* <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>{" "} */}
              </div>
              <div className="pagination-text-mobile">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageCount}
                  </strong>{" "}
                </span>
              </div>
              <div className="pagination-text-mobile">
                <span>
                  Go to page:{" "}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                  />
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DataTable;
