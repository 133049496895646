import {
  faBuildingColumns,
  faCodeFork,
  faCoins,
  faEquals,
  faEye,
  faImage,
  faLock,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCollectionWithSaleInfo } from "cli/TemplateAPI";
import { contractAddress, rankingSpecificContractAddress } from "config";
import { useContext as useGlobalContext } from "context";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // For blur
import { Column } from "react-table";
import DataTable, {
  CustomColumn,
  DataItem,
  isDataItem,
  isDataItemWithAddress,
  isItemWithSaleInfo,
} from "./DataTable";
import "./Ranking.scss";

const Ranking: React.FC = () => {
  const columns: CustomColumn<any>[] = [
    {
      Header: "Rank",
      accessor: "rankingIndex" as const,
      className: "center-align",
      width: 100,
      mobileWidth: 60,
    },
    {
      Header: "Image",
      accessor: "url",
      Cell: ({ row }: { row: any }) => (
        <div className="image-thumbnail">
          <LazyLoadImage
            alt={row.original.name}
            src={row.original.url}
            effect="blur" // Optional blur effect
          />
        </div>
      ),
      width: 60,
      mobileWidth: 60,
    },
    {
      Header: `${isMobile ? "ID" : "SpaceRobot ID"}`,
      accessor: "name" as const,
      Cell: ({ row }: { row: any }) => (
        <div className="name-container">
          <div className="td-wrapper equal">
            <div className="svg-equal-icon">
              {row.original.isHeadAndBodyMatching && (
                <FontAwesomeIcon
                  icon={faEquals}
                  className={
                    isMobile
                      ? "icon-mobile show-image-icon"
                      : "icon show-image-icon"
                  }
                  title="Matching Head and Body"
                />
              )}
            </div>
            {!row.original.isHeadAndBodyMatching && (
              <div style={{ width: "26px" }}></div>
            )}
          </div>

          <div className="td-wrapper">
            {row.original.name.replace("Space Robots", "")}
          </div>
          <div className="td-wrapper"></div>
        </div>
      ),
      width: 150,
      mobileWidth: 60,
    },
    // {
    //   Header: `${isMobile ? "TA" : "Total Attack"}`,
    //   accessor: "ta" as const,
    //   className: "center-align",
    //   width: 120,
    //   mobileWidth: 50,
    //   skipForMobilePortraitMode: true,
    // },
    // {
    //   Header: `${isMobile ? "TD" : "Total Defense"}`,
    //   accessor: "td" as const,
    //   className: "center-align",
    //   width: 120,
    //   mobileWidth: 50,
    //   skipForMobilePortraitMode: true,
    // },
    {
      Header: `${isMobile ? "Power" : "Power Score"}`,
      accessor: "powerScore" as const,
      className: "center-align",
      width: 120,
      mobileWidth: 40,
    },

    {
      Header: `${isMobile ? "Rarity" : "Rarity Score"}`,
      accessor: "rarityScore" as const,
      className: "center-align",
      width: 120,
      mobileWidth: 40,
    },
    {
      Header: "Status",
      accessor: (row: DataItem) => row,
      id: "usdAmmount",
      Cell: ({ value }: { value: DataItem }) => {
        if (isItemWithSaleInfo(value)) {
          const { price, token, marketUrl, owner } = value.saleInfo;

          return (
            <div className="sale-info-container">
              {`${price} ${token}`}
              <a
                href={marketUrl}
                target="_blank"
                rel="noreferrer"
                className="sale-button"
              >
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  className={isMobile ? "icon-mobile" : "icon"}
                  title="Buy SpaceRobot"
                />
              </a>
            </div>
          );
        } else if (isDataItemWithAddress(value)) {
          const isLocked = value.owner.address == contractAddress;
          if (isLocked) {
            return (
              <div className="sale-info-container">
                {isMobile ? "" : "Locked"}

                <span className="info-button">
                  <FontAwesomeIcon
                    icon={faLock}
                    className={isMobile ? "icon-mobile" : "icon"}
                    title="Item locked in RoboPack"
                  />
                </span>
              </div>
            );
          } else if (
            rankingSpecificContractAddress.includes(value.owner.address)
          ) {
            return (
              <div className="sale-info-container">
                {isMobile ? "" : "Proteo"}
                <div className="info-button">
                  <FontAwesomeIcon
                    icon={faCodeFork}
                    className={isMobile ? "icon-mobile" : "icon"}
                    title="Item staked at Proteo"
                  />
                </div>
              </div>
            );
          } else if (value.owner.address.startsWith("erd1qqqqqqqqqqqqq")) {
            return (
              <div className="sale-info-container">
                {isMobile ? "" : "Smart Contract"}
                <div className="info-button">
                  <FontAwesomeIcon
                    icon={faBuildingColumns}
                    className={isMobile ? "icon-mobile" : "icon"}
                    title="Item locked in a smart contract"
                  />
                </div>
              </div>
            );
          } else if (isDataItem(value)) {
            const { assetId } = value;
            const viewUrl = `https://xoxno.com/nft/${assetId}`;
            return (
              <div className="sale-info-container">
                {isMobile ? "" : "Send Offer"}
                <a
                  href={viewUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="sale-button"
                >
                  <FontAwesomeIcon
                    icon={faCoins}
                    className={isMobile ? "icon-mobile" : "icon"}
                    title="Send an offer on XOXNO"
                  />
                </a>
              </div>
            );
          } else {
            return null;
          }
        }
      },
      width: 160,
      mobileWidth: 80,

      // disableFilters: true,
      // disableSortBy: true,
    },
    // {
    //   Header: "address",
    //   accessor: "address",
    //   Cell: ({ row }: { row: any }) => (
    //     <div>
    //       {row.original.owner.address}
    //     </div>
    //   ),
    //   width: 80,
    //   mobileWidth: 40,
    // },
    // {
    //   Header: "herotag",
    //   accessor: 'owner.herotag',
    //   Cell: ({ row }: { row: any }) => (
    //     <div>
    //       {row.original.owner.herotag}
    //     </div>
    //   ),
    //   width: 80,
    //   mobileWidth: 40,
    // },
    // {
    //   id: "combined",
    //   Header: "Combined",
    //   accessor: (row: any) => `${row.name} ${row.owner.herotag} ${row.owner.address}`,  // Concatenate the two fields
    //   // other properties...
    // },
    {
      id: "combined",
      Header: "Combined",
      accessor: (row: any) => {
        const herotag = row.saleInfo
          ? row.saleInfo.owner.herotag
          : row.owner.herotag;
        const address = row.saleInfo
          ? row.saleInfo.owner.address
          : row.owner.address;
        return `${row.name} ${herotag} ${address}`;
      },
      // other properties...
    },
  ] as unknown as Column<any>[];

  const ctx = useGlobalContext();
  const address = ctx.address;

  const [allRobots, setAllRobots] = useState<Array<any>>([]);
  const [lastDate, setLastDate] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  // setAllRobots(sample_json.data as Array<any>);
  // useEffect(() => {
  // const readableDate = new Intl.DateTimeFormat("en-US", {
  //   year: "numeric",
  //   month: "long",
  //   day: "2-digit",
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   second: "2-digit",
  //   timeZone: "UTC",
  //   timeZoneName: "short",
  // }).format(new Date(sample_json.lastStored));
  // setLastDate(readableDate);
  // });
  type JsonResponseType = {
    data: any[]; // Replace 'any' with the type of objects in the data array, if you have a specific type
    lastStored: string;
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const data = await getCollectionWithSaleInfo();
        // type JsonResponseType = {
        //   data: any[]; // Replace 'any' with the type of objects in the data array, if you have a specific type
        //   lastStored: string;
        // };

        // const sample_json: JsonResponseType = require("../../../assets/sample_api_response_1.json");
        // const data = sample_json;
        setAllRobots(data.data); // update the state with the fetched data array

        const readableDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: "UTC",
          timeZoneName: "short",
        }).format(new Date(data.lastStored));
        setLastDate(readableDate);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false); // Set loading state to false when fetching ends
      }
    }

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? ( // Check if loading
        <div className="loading-div-container">
          <div className="loading-div">Loading...</div>
        </div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <DataTable
          columns={columns}
          data={allRobots}
          lastDate={lastDate}
          address={address}
        />
      )}
    </>
  );
};

export default Ranking;
