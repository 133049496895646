import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import LoginModal from "components/LoginModal";
import { collections, network } from "config";
import { useContext, useDispatch } from "context";
import useResponsive from "hooks/useResponsive";
import React, { useEffect } from "react";
import { getBackgroundForRoute } from "routes";
import { NFTType, getAccountNfts } from "../../cli/ElrondAPI";
import Message from "../Message";
import AppNav from "./AppNav";
import Footer from "./Footer";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { address } = useGetAccountInfo();
  const { message, showLogin } = useContext();
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();

  async function fetchNFTs(
    accountAddress: string,
    collectionIds: string[],
  ): Promise<Array<NFTType>> {
    const { data: NFTs } = await getAccountNfts({
      apiAddress: network.apiAddress,
      address: accountAddress,
      timeout: 3000,
      collections: collectionIds,
    });

    return NFTs ?? [];
  }

  useEffect(() => {
    dispatch({ type: "addAddress", address: address });
    if (address) {
      const collectionIds = collections.map((c) => c.identifier);
      fetchNFTs(address, collectionIds).then((nfts) => {
        dispatch({ type: "setNFTsAccount", nftsAccount: nfts });
      });
    }
  }, [address]);

  function handleHideLogin() {
    dispatch({ type: "hideLogin" });
  }

  function handleHideMessage() {
    dispatch({ type: "hideMessage" });
    message.onHide && message.onHide();
  }

  const handleOnClickConnect = () => {
    dispatch({ type: "showLogin" });
  };

  //TODO
  const background = "main-background";
  return (
    <div
      className={`main ${background} d-flex flex-column flex-fill wrapper oveflow-hidden`}
    >
      <AppNav />
      <main className="d-flex flex-column flex-grow-1 space-robots overflow-auto">
        <LoginModal
          show={!address && (showLogin || false)}
          handleClose={handleHideLogin}
        />

        <Message
          show={!!message && message.show}
          title={message?.title}
          text={message?.text}
          text2={message?.text2}
          onHide={handleHideMessage}
          danger={message?.danger}
          onConfirm={message?.onConfirm}
          onCancel={message?.onCancel}
        />
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
