import {
  faDiscord,
  faTelegram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBlog } from "@fortawesome/free-solid-svg-icons";
import SocialLink from "components/SocialLink";
import "./styles.scss";

const Footer = () => {
  return (
    <footer className="ps-3 pe-3 container-fluid">
      <div className="col pt-3 pb-3 container d-flex justify-content-between align-items-center">
        <div className="footer-container">
          <span>&copy; 2023 SpaceRobots. All rights reserved.</span>
        </div>
        <div className="footer-links">
          <SocialLink
            link="https://spacerobotsclub.com/discord"
            icon={faDiscord}
            title="Discord"
          />
          <SocialLink
            link="https://twitter.com/SpaceRobotsNFT"
            icon={faTwitter}
            title="Twitter"
          />
          <SocialLink
            link="https://t.me/spacerobots"
            icon={faTelegram}
            title="Telegram"
          />
          <SocialLink
            link="https://blog.spacerobotsclub.com/"
            icon={faBlog}
            title="Blog"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
