import React, { useState, useEffect } from "react";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useNavigate } from "react-router-dom";
import { NFTType, getAccountNfts } from "cli/ElrondAPI";
import { collections, network } from "config";
import { routeNames } from "routes";
import "./styles.scss";
import { ContextProvider } from "./Context";
import { useDispatch } from "context";
import Warehouse from "./Warehouse";
import { useContext as useGlobalContext } from "context";
import Claim from "components/Claim";
import Mobile from "components/Mobile";

const WareHouseMobile = () => {
  const navigate = useNavigate();
  const { address } = useGetAccountInfo();
  const dispatch = useDispatch();
  const ctx = useGlobalContext();

  const handleOnUpgrade = () => {
    navigate(routeNames.upgradeMobile);
  };

  return (
    <ContextProvider initialState={{ roboPartsLocked: {} }}>
      <Claim title="MY INVENTORY">
        <p className="mt-4 text-center">
          <strong>*/: The Inventory //:</strong>
        </p>
        <span className="text-center">
          Data storage to manage Space Robots items collected.
        </span>
      </Claim>
      {address && (
        <div className="container inventory-container px-4 mt-5">
          <h2
            className="pb-2 mb-2"
            style={{ borderBottom: "1px solid #ffffff", lineHeight: "28.8px" }}
          >
            INVENTORY
          </h2>
          <Warehouse nfts={ctx.nftsAccount} onUpgrade={handleOnUpgrade} />
        </div>
      )}
    </ContextProvider>
  );
};

export default WareHouseMobile;
