import axios from "axios";
import { templateApiAddress } from "config";

export interface Template {
  id?: string;
  spaceRobot: string;
  roboParts: Array<string>;
}

async function createTemplate({
  address,
  template,
}: {
  address: string;
  template: Template;
}): Promise<any> {
  return axios.post(`${templateApiAddress}/template/${address}`, template);
}

async function getTemplates(address: string): Promise<Array<Template>> {
  return axios
    .get(`${templateApiAddress}/template/${address}`)
    .then(({ data }) => data);
}

async function getCollectionWithSaleInfo(): Promise<any> {
  try {
    const response = await axios.get(`${templateApiAddress}/getRankingData`);
    console.log(response);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch collection with sale info.");
  }
}


async function deleteTemplate({
  address,
  templateId,
}: {
  address: string;
  templateId: string;
}) {
  return axios.delete(
    `${templateApiAddress}/template/${address}/${templateId}`,
  );
}

async function getImages(id: string) {
  return axios.get(`${templateApiAddress}/images/${id}`).then(({ data }) => {
    console.log(data);
    return data;
  });
}

export {
  createTemplate,
  getTemplates,
  deleteTemplate,
  getImages,
  getCollectionWithSaleInfo,
};