import { NFTType } from "cli/ElrondAPI";

export interface MessageType {
    show: boolean;
    title?: string;
    text?: string;
    text2?: string;
    danger?: boolean;
    onConfirm?: any;
    onCancel?: any;
    onHide?: any;

}
export interface StateType {
  showLogin: boolean;
  address: string;
  message: MessageType;
  spaceRobot?: NFTType;
  nftsAccount: Array<NFTType>;
}

const initialState = (): StateType => {
    return {
      showLogin: false,
      message: {show: false},
      address:  "",
      nftsAccount: []
    };
};

export default initialState;