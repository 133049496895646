import { CollectionType, RoboPackType, TagType } from "domain/types";

export const dAppName = "Space Robots";
export const decimals = 2;
export const denomination = 18;
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 10000000;
export const gasPerDataByte = 1500;

export const walletConnectBridge = "https://bridge.walletconnect.org";
export const walletConnectDeepLink =
  "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/";

export const contractAddress =
  "erd1qqqqqqqqqqqqqpgqdkf2ysd074em6de5a0tcd8w80j6jh5jg4djq490xzw";
export const contractAddress2 =
  "erd1qqqqqqqqqqqqqpgqtl079r7nedk455u2s3flnu9fykwmp2ey9p4skcgnar";
export const rankingSpecificContractAddress = [
  "erd1qqqqqqqqqqqqqpgq6ntyuma7az74dhwalhjwywyw0g9fyh0jznyq38sngv",
];

export const walletConnectV2ProjectId = "e7f88628b5b257d384058570d9a634cb";
export const network = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.multiversx.com",
  apiAddress: "https://api.multiversx.com",
  gatewayAddress: "https://gateway.multiversx.com",
  explorerAddress: "http://explorer.multiversx.com/",
};

export const roboPacks: RoboPackType[] = [
  {
    identifier: "SPACEROBOT-bfbf9d-02",
    name: "ROBOPACK PH1",
    label: "PH1",
    enabled: true,
    specification:
      "RoboPack PH1 is a Space Robots NFT that was available to mint on 2021-11.",
    pieces: ["1X Space Robots NFT", "2X Robots parts NFT"],
    imageSM: "/assets/RoboPack_PH1-medium.png",
    image: "/assets/RoboPack_PH1.png",
    video: "https://img.spacerobots.club/video/HD/RoboPackPH1.mp4",
    unlockMethodSuffix: "1",
    unboxingVideo: "/assets/unboxing-ph1.mp4",
  },
  {
    identifier: "SPACEROBOT-bfbf9d-04",
    name: "ROBOPACK PH2",
    label: "PH2",
    enabled: true,
    specification:
      "RoboPack PH2 is a Space Robots NFT that was available to mint on 2021-12.",
    pieces: ["1X Space Robots NFT", "1X Robots parts NFT"],
    imageSM: "/assets/RoboPack_PH2-medium.png",
    image: "/assets/RoboPack_PH2.png",
    video: "https://img.spacerobots.club/video/HD/RoboPackPH2.mp4",
    unlockMethodSuffix: "2",
    unboxingVideo: "/assets/unboxing-ph2.mp4",
  },
  {
    identifier: "SPACEROBOT-bfbf9d-0d",
    name: "ROBOPACK PHX",
    label: "PHX",
    enabled: true,
    specification:
      "RoboPack PHX is a Space Robots NFT that was distributed to SpaceRobots holders during xDays event.",
    pieces: ["5X Robots parts NFT"],
    imageSM: "/assets/RoboPack_PHX-medium.png",
    image: "/assets/RoboPack_PHX.png",
    video: "https://img.spacerobots.club/video/HD/RoboPackPHX.mp4",
    unlockMethodSuffix: "x",
    unboxingVideo: "/assets/RobopackPHX-Unlocking-Full-16-9.mp4",
    unboxingVideoMobile: "/assets/RobopackPHX-Unlocking-Full-720p.mp4",
  },
];

export const collectionsMap: { [key: string]: CollectionType } = {
  SpaceRobots: {
    identifier: "SRC-27d8ff",
    imagesCopy: "https://img.spacerobots.club/",
    nftImageAttribute: "nonce",
    upgradeable: true,
    expectedSize: 7777,
  },
  RoboParts: {
    identifier: "SRP-ec2514",
    imagesCopy: "https://img.spacerobots.club/parts/",
    nftImageAttribute: "name",
    upgradeable: false,
    expectedSize: 200,
  },
};

export const collections = Object.values(collectionsMap);

export const collectionSpaceRobots = collectionsMap["SpaceRobots"];
export const collectionRoboParts = collectionsMap["RoboParts"];

export const tags: TagType[] = [
  {
    name: "Robot",
    label: "ROBOT",
    image: "/assets/tags/robot.svg",
  },
  {
    name: "Head Slot",
    label: "HEADSLOT",
    image: "/assets/tags/headslot.svg",
    imageRemark: "/assets/tags/headslot-remark.svg",
  },
  {
    name: "Ears",
    label: "EARS",
    image: "/assets/tags/ears.svg",
    imageRemark: "/assets/tags/ears-remark.svg",
  },
  {
    name: "Eyes",
    label: "EYE",
    image: "/assets/tags/eye.svg",
    imageRemark: "/assets/tags/eye-remark.svg",
  },
  {
    name: "Mouth",
    label: "MOUTH",
    image: "/assets/tags/mouth.svg",
    imageRemark: "/assets/tags/mouth-remark.svg",
  },
  {
    name: "Hands",
    label: "HANDS",
    image: "/assets/tags/hands.svg",
    imageRemark: "/assets/tags/hands-remark.svg",
  },
];

export const SpaceRobotPartName = ["Body", "Head"].concat(
  tags.map((tag) => tag.name),
);

export const mobileBreakpoint = 576;

export const templateApiAddress = "https://json.spacerobotsclub.com:8080";
