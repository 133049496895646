import React, { useMemo, useState } from "react";
import { NFTType } from "cli/ElrondAPI";
import { collections, collectionSpaceRobots, network, tags } from "config";
import { useDispatch } from "context";
import { CollectionType } from "domain/types";
import { getAttributeFromNFT, ROBOPART_ATTRIBUTE_KIND } from "utils";
import RoboParts from "./RoboParts";
import Tags from "./Tags";

function filterInventoryByTag(
  NFTlist: Array<NFTType>,
  tag: string,
): Array<NFTType> {
  return tag == "Robot"
    ? NFTlist?.filter(
        (nft) => nft.collection == collectionSpaceRobots.identifier,
      )
    : NFTlist?.filter((nft) => {
        const [attr] = getAttributeFromNFT({
          nft,
          attributes: [ROBOPART_ATTRIBUTE_KIND],
        });
        return attr == tag;
      });
}
interface WarehousePropsType {
  nfts: Array<NFTType>;
  onUpgrade: () => void;
}
const Warehouse = ({ nfts: rawNFTs, onUpgrade }: WarehousePropsType) => {
  const [filterTagIndex, setFilterTagIndex] = useState<number>();
  const dispatch = useDispatch();

  function orderByListOfCollections(
    NFTlist: Array<NFTType>,
    _collections: CollectionType[],
  ) {
    return _collections.flatMap((col) => {
      return NFTlist.filter((nft) => nft.collection === col.identifier);
    });
  }

  function addExplorerLinkToNFT(NFTlist: Array<NFTType>) {
    return NFTlist.map((nft) => ({
      ...nft,
      link: `${network.explorerAddress}nfts/${nft.identifier}`,
    }));
  }

  const NFTs = useMemo(() => {
    /**
     * The parts of robots are SFT's and means that the account can own more than one.
     * As it will be shown an item by unit, it is necessary to add an item by unit.
     */
    const NFTsFlatByBalance = rawNFTs?.flatMap((nft) =>
      Array(Number(nft.balance ?? 1)).fill(nft),
    );
    const NFTsOrderedByCollection = orderByListOfCollections(
      NFTsFlatByBalance || [],
      collections,
    );
    const roboPartNFTList = addExplorerLinkToNFT(NFTsOrderedByCollection);

    return roboPartNFTList;
  }, [rawNFTs]);

  const filterNFTs = useMemo(() => {
    if (filterTagIndex == undefined) {
      // No tag selected on filter
      return NFTs;
    } else {
      const tag = tags[filterTagIndex].name;
      const filtered = filterInventoryByTag(NFTs, tag);

      return filtered;
    }
  }, [NFTs, filterTagIndex]);

  const handleOnSelectFilterTag = (selectedTagIndex: number) => {
    setFilterTagIndex(selectedTagIndex);
  };

  const handleOnUpgrade = (nft: NFTType) => {
    dispatch({
      type: "setSpaceRobot",
      spaceRobot: nft,
    });
    onUpgrade && onUpgrade();
  };

  return (
    <>
      <Tags
        tags={tags}
        className="my-4 my-md-5"
        onSelect={handleOnSelectFilterTag}
      />
      <RoboParts
        className="mt-3"
        NFTs={filterNFTs}
        onUpgrade={handleOnUpgrade}
      />
    </>
  );
};

export default Warehouse;
