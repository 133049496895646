import React from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fontStyle } from "html2canvas/dist/types/css/property-descriptors/font-style";
import { routeNames } from "routes";
import { isMobile } from "react-device-detect";

interface ButtonType {
  label?: string;
  onClick?: any;
  className?: string;
  background?: string;
  href?: string;
  target?: string;
  icon?: any;
  type?: string;
}

const Button = ({
  label,
  onClick,
  className,
  background,
  href,
  target,
  icon,
}: ButtonType) => {
  const _className = "button" + (className ? ` ${className}` : "");

  let style = {};
  let spanStyle = {};
  if (background) {
    style = { backgroundColor: background };
  }
  if (isMobile && (label?.includes("login") || label?.includes("logout"))) {
    spanStyle = {...spanStyle, fontSize: "10px"}
  }
  if (
    label?.includes("BUY A") &&
    (location.pathname == routeNames.inventory ||
      location.pathname == routeNames.upgradeMobile ||
      location.pathname == routeNames.upgradeDesktop)
  ) {
    spanStyle = { ...spanStyle, fontSize: "8px" };
  }

  function handleOnClick(e: React.MouseEvent) {
    if (!!onClick) {
      e.preventDefault();
      onClick();
    }
  }
  return (
    <a
      href={href || "#"}
      className={_className}
      onClick={handleOnClick}
      style={style}
      target={target}
    >
      <span className="padding p-2" style={spanStyle}>
        {" "}
        {label}{" "}
      </span>
      {icon ? <FontAwesomeIcon icon={icon} size="lg" /> : ""}
    </a>
  );
};

export default Button;
