import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import "./style.scss";

const SocialLink = ({
  link,
  icon,
  title,
}: {
  link: string;
  icon: any;
  title: string;
}) => {
  return (
    <Nav.Link active href={link} target="__blank" className="link-octagon">
      {/* <img src={image} alt={title} /> */}
      <FontAwesomeIcon icon={icon} style={{ color: "white" }} />
    </Nav.Link>
  );
};

export default SocialLink;
