import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAccountNfts, getTransaction, getNft } from "cli/ElrondAPI";
import RoboPacks from "components/RoboPacks";
import UnlockPack from "components/UnlockPack";
import { useDispatch } from "context";
import useResponsive from "hooks/useResponsive";
import { RoboPackType } from "domain/types";
import { routeNames } from "routes";
import { getExternalImageOfNFT } from "utils";
import RobopackParts from "../../components/RobopackParts";
import Video from "../../components/Video";
import { collections, contractAddress, network, roboPacks } from "../../config";
import Claim from "components/Claim";

import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useGetPendingTransactions} from "@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions"
import { ContractSpaceRobots } from "domain/ContractSpaceRobots";

const Home = () => {
  const {
    hasPendingTransactions,
    pendingTransactionsArray,
  } = useGetPendingTransactions();
  const [txHash, setTxHash] = useState<any>();
  const { address } = useGetAccountInfo();
  const [accountPacks, setAccountPacks] = useState<any>([]);
  const [unlockedPack, setUnlockedPack] = useState<any>();
  const [showOpeningRobopack, setShowOpeningRobopack] = useState<boolean>();
  const [unboxingVideo, setUnboxingVideo] = useState<string>();

  const dispatch = useDispatch();
  const { isMobile } = useResponsive();

  async function fetchSFTBalance(accountAddress: string) {
    const identifiers = roboPacks.map(rp => rp.identifier);
    const { data: SFTs } = await getAccountNfts({
      apiAddress: network.apiAddress,
      address: accountAddress,
      timeout: 3000,
      identifiers,
    });

    return SFTs?.flatMap(sft => {
      const packInfo = roboPacks.filter(
        pk => pk.identifier == sft.identifier,
      )[0];
      return packInfo ? Array(Number(sft.balance || 0)).fill(packInfo) : [];
    });
  }

  // Get balance of Robopacks
  useEffect(() => {
    if (address) {
      fetchSFTBalance(address).then(packs => {
        setAccountPacks(packs);
      });
    }
  }, [address]);

  function txHasErrors(transaction: any): boolean {
    return (
      transaction.status
        .valueOf()
        .toLowerCase()
        .toLowerCase() != "success"
    );
  }

  function showTxError(transaction: any) {
    const returnMessage = transaction.results.reduce((_: any, curr: any) => {
      if (!!curr.returnMessage) return curr.returnMessage;
    }, "");
    dispatch({
      type: "showMessage",
      title: "Errors during unlocking",
      text: returnMessage,
      danger: true,
    });
  }

  async function fetchPartsFromTransaction(transaction: any): Promise<any> {
    const _parts: any[] = []; // Part of robot obtained
    let parts: any[] = []; // Part of robot obtained
    await Promise.all(
      transaction.operations.map(async (operation: any) => {
        if (operation.receiver == address) {
          const { data: nft } = await getNft({
            apiAddress: network.apiAddress,
            identifier: operation.identifier,
            timeout: 3000,
          });
          const externalImage = getExternalImageOfNFT(nft);
          _parts.push({
            collection: nft.collection,
            name: nft.name,
            image: externalImage, //nft.url
          });
        }
      }),
    );

    // Order by the list config.collections
    collections.forEach(col => {
      parts = parts.concat(_parts.filter(p => p.collection === col.identifier));
    });

    return { parts };
  }

  function retrieveSFTFromTransactionDataBecauseOfDelay(transaction: any) {
    /**
     * It is supposed that Elrond will return the raw transaction in the "data" attribute, it will do? :(
     *
     * Example: "ESDTNFTTransfer@5350414345524f424f542d316661646639@01@01@00000000000000000500ed3072aa02c4679cff9c2b4b6eb7b5e9760872b1ab64@6f70656e5f626f785f31"
     */
    location.hash = "#openingVideo";

    const data = atob(transaction.data);
    const sftCollection = Buffer.from(data.split("@")[1], "hex").toString();
    const sftNonce = data.split("@")[2];
    const sftIdentifider = `${sftCollection}-${sftNonce}`;
    
    const pack = roboPacks.filter(pack => pack.identifier === sftIdentifider)[0];

    return pack.unboxingVideoMobile && isMobile ? pack.unboxingVideoMobile : pack.unboxingVideo;
  }

  // Close Login
  useEffect(() => {
    dispatch({ type: "hideLogin" });
  }, [address]);

  useEffect(() => {
    if (hasPendingTransactions) {
      const lastTx = pendingTransactionsArray[0];

      //removeAllSignedTransactions();

      const _unboxingVideo = retrieveSFTFromTransactionDataBecauseOfDelay(
        lastTx[1]?.transactions[0],
      );

      setTimeout(() => {
        setTxHash(lastTx[1]?.transactions[0]?.hash);
        setUnboxingVideo(_unboxingVideo);
        setShowOpeningRobopack(true);
        setUnlockedPack(undefined);
      }, 2000);
    }
  }, [hasPendingTransactions]);

  const handleOnUnlock = (roboPack: RoboPackType) => {
    ContractSpaceRobots.unlock(
      roboPack.identifier,
      address,
      1,
      roboPack.unlockMethodSuffix,
    );
  };

  const handleOnOpeningVideoEnded = () => {
    if (txHash) {
      /**
       * I am sorry but it is necessary to fetch the transaction again as there is a delay in Elrond between confirmed transactions and propagation of the block :(
       */
      getTransaction({
        apiAddress: network.apiAddress,
        txHash: txHash,
        timeout: 3000,
      }).then(async transaction => {
        const _unlockedPack = await fetchPartsFromTransaction(transaction);
        setUnlockedPack(_unlockedPack);
      });
    }
    setShowOpeningRobopack(false);
  };

  const handleOnClickConnect = () => {
    dispatch({ type: "showLogin" });
  };

  return (
    <>
      <Claim title="RoboPACK UNLOCK">
        <p className="mt-4 text-center">
           Welcome to the RoboPack Unlock page!
        </p>
        <p className="mt-4 text-center">
           Each RoboPack contains a unique assortment of random items eagerly waiting to be revealed on this page.
        </p>
      </Claim>
      <div className="container-fluid">
        <div id="openingVideo" style={{ marginTop: "1em" }}>
          &nbsp;
        </div>
        {showOpeningRobopack && unboxingVideo && (
          <Video
            video={unboxingVideo}
            onEnded={handleOnOpeningVideoEnded}
            isUnlockingVideo={true}
          />
        )}
        {unlockedPack?.parts && (
          <RobopackParts
            parts={unlockedPack?.parts}
            visible={!!unlockedPack?.parts}
          />
        )}
        {address && !showOpeningRobopack && (
          <UnlockPack onUnlock={handleOnUnlock} accountPacks={accountPacks} />
        )}
        <RoboPacks roboPacks={roboPacks} />
      </div>
    </>
  );
};

export default Home;
