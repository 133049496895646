import React, { useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import "./styles.scss";
import Button from "../Button";

interface MessageType {
    title?: string;
    text?: string;
    text2?: string;
    show: boolean;
    danger?: boolean;
    onHide: any;
    onConfirm?: any;
    onCancel?: any;
}

const Message = ({title, text, text2, show, onHide, danger, onConfirm, onCancel}: MessageType)=>{
    const [confirming, setConfirming] = React.useState(false);

    const classNames = ["message"];
    if (danger) classNames.push("danger");

    const ref = React.useRef(null);

    useEffect(() => {
        setConfirming(false);
    }, [show, text, text2]);

    const handleOnConfirm = () => {
        if (onConfirm) {
            setConfirming(true);
            onConfirm();
        }
    };

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const handleOnHide = () => {
        setConfirming(false);
        if (onHide) {
            onHide();
        }
    };

    return (
        <Modal 
          ref={ref} 
          show={show} 
          onHide={handleOnHide} 
          className={classNames.join(" ")} 
          aria-labelledby="contained-modal-title-vcenter"
          size="xl"
          centered
        >
            <Modal.Header closeButton={!Boolean(onCancel)} />
            <Modal.Body >
                <Modal.Title className="text-center"><h3>{title}</h3></Modal.Title>
                <div style={{whiteSpace: 'pre-wrap'}}>
                    <h5 className="text-center">{text}</h5>
                    <h6 className="text-left">{text2}</h6>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center pb-4">
                {confirming && <Spinner animation="border"variant="light"/>}
                {!confirming && !!onConfirm && <Button label="CONTINUE" className="button-confirm me-md-5" onClick={handleOnConfirm}/>}
                {!confirming && !!onCancel && <Button label="DECLINE" className="button-confirm" onClick={handleOnCancel}/>}
            </Modal.Footer>
        </Modal>

    );
};

export default Message;