import React, { useState } from "react";
import { Fade } from "react-bootstrap";
import { useDispatch } from "context";
import { RoboPackType } from "domain/types";
import Button from "../Button";
import Frame from "../Frame";
import Video from "../Video";
import SliderPacks from "./SliderPacks";
import "./styles.scss";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";

const SelectRobopack = () => {
  return (
    <Frame
      className="select-robopack d-flex align-items-center"
      style={{ maxHeight: "700px" }}
    >
      <div className="container">
        <h2>SELECT A</h2>
        <h2>ROBOPACK</h2>
        <h2>TO UNLOCK</h2>
      </div>
    </Frame>
  );
};

interface UnlockPackType {
  className?: string;
  onUnlock: (robopack: RoboPackType) => void;
  accountPacks: RoboPackType[];
}
const UnlockPack = ({ className, onUnlock, accountPacks }: UnlockPackType) => {
  const [selectedPackIndex, setSelectedPackIndex] = useState<
    number | undefined
  >(undefined);

  const dispatch = useDispatch();

  const { address } = useGetAccountInfo();
  if (!address) {
    return <></>;
  }

  const classNames = ["unlockpack", "container"];
  classNames.push(className || "");

  const isPackSelected = (): boolean => {
    return selectedPackIndex != undefined;
  };

  const handleOnSelectPack = (pack: number) => {
    setSelectedPackIndex(pack);
  };

  const handleOnConfirmUnlock = (roboPack: RoboPackType) => {
    setSelectedPackIndex(undefined);
    dispatch({ type: "hideMessage" });
    onUnlock(roboPack);
  };

  const handleOnCancelUnlock = () => {
    setSelectedPackIndex(undefined);
    dispatch({ type: "hideMessage" });
  };

  const handleOnUnlockPack = () => {
    if (selectedPackIndex != undefined) {
      dispatch({
        type: "showMessage",
        title: "Unlocking RoboPack",
        text: `You are going to unlock a RoboPack ${accountPacks[selectedPackIndex].label}, please confirm`,
        danger: false,
        onConfirm: () => handleOnConfirmUnlock(accountPacks[selectedPackIndex]),
        onCancel: handleOnCancelUnlock,
      });
    }
  };

  return (
    <div className={classNames.join(" ")}>
      <div id="openingVideo" style={{ marginTop: "1em" }}>
        &nbsp;
      </div>
      {accountPacks && accountPacks.length == 0 && (
        <div className="container text-center">
          <h2>*#/ ROBOPACK NOT FOUND</h2>
          <p>
            You have 0 RoboPack NFTs in your inventory. Acquire some to start
            the unlocking proces
          </p>
        </div>
      )}
      {accountPacks && accountPacks.length > 0 && selectedPackIndex == undefined && (
        <SelectRobopack />
      )}
      {selectedPackIndex != undefined && (
        <Video video={accountPacks[selectedPackIndex].video} loop={true} isUnlockingVideo={false}/>
      )}
      <Fade in={isPackSelected()}>
        <div
          className="text-center"
          style={{ marginTop: "3em", marginBottom: "3em" }}
        >
          <Button label="UNLOCK" onClick={handleOnUnlockPack} />
        </div>
      </Fade>
      {accountPacks && (
      <SliderPacks
      packs={accountPacks}
      onSelect={handleOnSelectPack}
      initialSelected={selectedPackIndex}
    />
      )}

    </div>
  );
};

export default UnlockPack;
