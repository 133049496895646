import React from "react";
import "./styles.scss";
import Templates from "components/Templates";
import { ContextProvider } from "./Context";
import { useDispatch } from "context";
import Claim from "components/Claim";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";

const UpgradeMobile = () => {
  const { address } = useGetAccountInfo();
  const dispatch = useDispatch();

  return (
    <ContextProvider initialState={{ roboPartsLocked: {} }}>
      <Claim title="MY TEMPLATES">
        <p className="mt-4 text-center">
          <strong>
            */: The Templates //: Data storage to manage previously saved
            Templates during the Upgrading protocol.
          </strong>
        </p>
        <span className="text-center">
          {"//"}: A Template can be downloaded by pressing the top-left Download
          button, or deleted by pressing the top-right corner X button.
        </span>
      </Claim>
      {address && (
        <div className="container inventory-container px-4">
          <h2
            className="pb-2 mb-2 mt-5"
            style={{ borderBottom: "1px solid #ffffff", lineHeight: "28.8px" }}
          >
            TEMPLATES
          </h2>
          <Templates address={address} />
        </div>
      )}
    </ContextProvider>
  );
};

export default UpgradeMobile;
