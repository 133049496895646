import { NFTType } from "cli/ElrondAPI";
import { collections, SpaceRobotPartName } from "config";

const ROBOT_ATTRIBUTE_ATTACK = 'Total Attack';
const ROBOT_ATTRIBUTE_DEFENSE = 'Total Defense';
const ROBOPART_ATTRIBUTE_ATTACK = 'Attack';
const ROBOPART_ATTRIBUTE_DEFENSE = 'Defense';
const ROBOPART_ATTRIBUTE_KIND = 'RoboPart';

function getExternalImageOfNFT(nft: NFTType): string {
    const {imagesCopy, nftImageAttribute} = collections.filter(col=>col.identifier === nft.collection)[0];
    return `${imagesCopy}${nft[nftImageAttribute]}.jpg`;
}

function parseAttributes(nft: NFTType): {[key: string]: any} {
    return atob(nft.attributes ?? '')
      .split(';')
      .reduce((acc, curr) => {
        const [key, value] = curr.split(':');
        acc[key] = value.trim();
        return acc;
        }
        , {} as any);
}
  
interface getAttributeFromNFT {
    nft: NFTType
    attributes: Array<string>
}
function getAttributeFromNFT<T>({nft, attributes}: getAttributeFromNFT): Array<T> {
    const nftAttributes = parseAttributes(nft);
    return attributes.map(item => nftAttributes[item] as T);
}
  
  
function getAttackDefenseByRoboPart({spaceRobot, roboPartsUpgrade}: {spaceRobot?: NFTType, roboPartsUpgrade: Array<NFTType>}): {[key: string]: {attack?: number, defense?: number}} {
    const roboParts: {[key: string]: {attack?: number, defense?: number}} = {}; // string because we do not know if the value of the attributes of the NFT are right
    // Get Attack and Defense of the Space Robot
    if (spaceRobot) {
        SpaceRobotPartName.map(partName => {
            const [value] = getAttributeFromNFT<string>({nft: spaceRobot, attributes: [partName]});
    
            // Get attack and defense from trait value
            // For "VR Headset A8 D7", attack is 8 and defense is 7
            if (value) {
                roboParts[partName] = {};
                value.split(" ").slice(-2).map(attack_or_defense => {
                    if (attack_or_defense.startsWith("D")) {
                        roboParts[partName].defense = Number(attack_or_defense.replace("D", ""));
                    } else if (attack_or_defense.startsWith("A")) {
                        roboParts[partName].attack = Number(attack_or_defense.replace("A", ""));
                    }
                });
            }
        });
    }

    //  Get Attack and Defense of the RoboParts
    roboPartsUpgrade.map(part => {
        const [partName, attack, defense] = getAttributeFromNFT<string>({nft: part, attributes: [ROBOPART_ATTRIBUTE_KIND, ROBOPART_ATTRIBUTE_ATTACK, ROBOPART_ATTRIBUTE_DEFENSE]});
        roboParts[partName] = {
            attack: Number(attack),
            defense: Number(defense)
        };
    });
    
    return roboParts;
}

function getAttackDefense({spaceRobot, roboPartsUpgrade}: {spaceRobot?: NFTType, roboPartsUpgrade: Array<NFTType>}): {attack: number, defense: number} {
    const templateAttackDefense = getAttackDefenseByRoboPart({spaceRobot, roboPartsUpgrade});

    return {
        attack: Object.values(templateAttackDefense).reduce((acc, curr) => (curr.attack ?? 0) + acc, 0),
        defense: Object.values(templateAttackDefense).reduce((acc, curr) => (curr.defense ?? 0) + acc, 0)
    };
}

export {
    ROBOT_ATTRIBUTE_ATTACK,
    ROBOT_ATTRIBUTE_DEFENSE,
    ROBOPART_ATTRIBUTE_ATTACK,
    ROBOPART_ATTRIBUTE_DEFENSE,
    ROBOPART_ATTRIBUTE_KIND,
    getExternalImageOfNFT,
    parseAttributes,
    getAttributeFromNFT,
    getAttackDefenseByRoboPart,
    getAttackDefense
};