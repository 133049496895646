import React from "react";

import "./styles.scss";
import Button from "../Button";
import Frame from "../Frame";
interface RoboPackType {
  name?: string;
  specification?: string;
  pieces?: string[];
  image?: any;
  onClickMarket?: any;
}
const RoboPack = ({name, specification, pieces, image, onClickMarket}: RoboPackType) => {
  return (
    <Frame className="robopack pb-3 pb-lg-2">
      <h2 className="pb-md-2 pb-lg-3 pb-xl-4">{name}</h2>
      <p className="pb-md-2 pb-lg-3 pb-xl-4">{specification}</p>
      <span>It contain the following{name != 'ROBOPACK PHX' ? ' random' : ''} items:</span>
      {pieces?.map((piece, index)=> 
        <div key={index}><span>{piece}</span></div>
      )}
      <div className="mt-5 mb-5">
        <img src={image} />
      </div>
      <div className="mb-4"><Button label="SECONDARY MARKET" onClick={onClickMarket} href="https://deadrare.io/collection/SPACEROBOT-bfbf9d"/></div>
    </Frame>
  );
};

const RoboPacks = ({roboPacks}: {roboPacks: RoboPackType[]}) => {
  return (
    <div className="container pt-5">
      {roboPacks.map((roboPack, index) => 
        <div key={index} className="row mt-5">
          <div className="col">
            <RoboPack {...roboPack} />
          </div>
        </div>
      )}
    </div>    
  );
};

export default RoboPacks;