import React from "react";
import Button from "../Button";
import { logout } from "@multiversx/sdk-dapp/utils/logout";
import { useNavigate } from "react-router-dom";
import { faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from 'react-device-detect';


interface UnloggedType {
  onClickConnect?: any;
  className?: string;
  address: string;
}

const Connect = ({ className, onClickConnect, address }: UnloggedType) => {
  const navigate = useNavigate();
  const addressFormated = "... " + address.slice(-6) + " ";

  const handleLogout = (event: React.ChangeEvent<HTMLInputElement>) => {
    logout(`${window.location.origin}/`);
    navigate("/");
  };
  return (
    <div className={className}>
      <div className="row">
        <div className="col text-center">
          {address ? (
            !isMobile ? (
            <Button
              label={"DISCONNECT " + addressFormated}
              onClick={handleLogout}
              className="logout"
              icon = {faSignOutAlt}
              type = "submit"
              />
              ) :
              (
                <Button
                label={"logout " + addressFormated}
                onClick={handleLogout}
                className="logout"
                icon = {faSignOutAlt}
                type = "submit"
                />
              )

          ) : (
            !isMobile ? (
            <Button
              label="CONNECT WALLET"
              onClick={onClickConnect}
              className="login"
            /> ) : 
            (
              <Button
                label="login"
                onClick={onClickConnect}
                className="login"
                icon={faSignInAlt}
              /> )
          )}
        </div>
      </div>
    </div>
  );
};

export default Connect;
