import {
  faBorderAll,
  faLock,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "./styles.scss";

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
]);

const propTypes = {
  labels: PropTypes.shape({
    left: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: valueType,
    }),
    center: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: valueType,
    }),
    right: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: valueType,
    }),
  }),
  onChange: PropTypes.func.isRequired,
  styles: PropTypes.object,
};

const defaultProps = {
  labels: {
    left: {
      title: "left",
      value: "left",
    },
    center: {
      title: "center",
      value: "center",
    },
    right: {
      title: "right",
      value: "right",
    },
  },
  onChange: (value: any) => console.log("value:", value),
};

const TripleToggleSwitch = (props: any) => {
  const [switchPosition, setSwitchPosition] = useState("center");
  const [animation, setAnimation] = useState<string | null>(null);
  const getSwitchAnimation = (value: any) => {
    let animation = null;
    if (value === "center" && switchPosition === "left") {
      animation = "left-to-center";
    } else if (value === "right" && switchPosition === "center") {
      animation = "center-to-right";
    } else if (value === "center" && switchPosition === "right") {
      animation = "right-to-center";
    } else if (value === "left" && switchPosition === "center") {
      animation = "center-to-left";
    } else if (value === "right" && switchPosition === "left") {
      animation = "left-to-right";
    } else if (value === "left" && switchPosition === "right") {
      animation = "right-to-left";
    }
    props.onChange(value);
    setSwitchPosition(value);
    setAnimation(animation);
  };

  return (
    <div
      className="main-container"
      style={
        switchPosition == "left" || switchPosition == "right"
          ? { backgroundColor: "#015949" }
          : { backgroundColor: "#7dc1b5" }
      }
    >
      <div className={`switch ${animation} ${switchPosition}-position`}></div>
      <input
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id="left"
        type="radio"
        value="left"
      />
      <label
        className={`left-label ${switchPosition === "left" && "black-font"}`}
        htmlFor="left"
      >
        <FontAwesomeIcon
          icon={faUnlock}
          className="icon-switch"
          color={switchPosition == "left" ? "gray" : "black"}
        />
      </label>
      <input
        //defaultChecked what is the default used for ? Do we need it? If we comment it, the toggle works left-center-right and the functionality still persists
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id="center"
        type="radio"
        value="center"
      />
      <label
        className={`center-label ${
          switchPosition === "center" && "black-font"
        }`}
        htmlFor="center"
      >
        {/* <FontAwesomeIcon
        icon={faBorderAll}
        className="icon-switch"
      /> */}
      </label>

      <input
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id="right"
        type="radio"
        value="right"
      />
      <label
        className={`right-label ${switchPosition === "right" && "black-font"}`}
        htmlFor="right"
      >
        <FontAwesomeIcon
          className="icon-switch"
          icon={faLock}
          color={switchPosition == "right" ? "gray" : "black"}
        />
      </label>
    </div>
  );
};

TripleToggleSwitch.propTypes = propTypes;
TripleToggleSwitch.defaultProps = defaultProps;

export default TripleToggleSwitch;
